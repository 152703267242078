<template>
	<div class="layout-topbar">
        <div class="layout-sup">
			<div class="layout-topbar-logo" @click="MenuToggle" style="background-color: brown;">
				<span><strong style="color: black;"  v-if="this.$store.state.autenticado">Batallón San Félix</strong></span>
			</div>
			<div class="layout-topbar-icons">
				<button class="p-link layout-menu-button" @click="MenuToggle" v-if="this.$store.state.autenticado">
					<span class="pi pi-bars"></span>
				</button>
				<button class="p-link" style="text-align: center;" @click="Logout()" v-if="this.$store.state.autenticado">
					<span class="layout-topbar-item-text">Salir</span>
					<span class="layout-topbar-icon pi pi-fw pi-power-off"></span><br>
					<span class="ocultar">Salir</span>
				</button>
			</div>
		</div>
        <div class="layout-inf">
			<!--
			<button class="p-link layout-topbar-logo" v-if="this.$store.state.autenticado">
				<strong style="margin-left: 20px;fontSize: 1.5rem;color: #575756;">Período: {{$store.state.periodo}} </strong>	
			</button>
			-->			
		</div>
		
	</div>
    <Notificacion/>

</template>

<script>
import InactiveJS from "inactivejs";
import { defineAsyncComponent } from "@vue/runtime-core";

export default {
	components: {
		Notificacion: defineAsyncComponent(() => import('./components/Notificacion.vue')),
	},
    data() {
        return {
			displayLogin: true,
            displayRegister: false,
		}
	},
	created() {
		const onAway = () => {
			if (this.$store.state.autenticado){
				this.Tiempo();
			}		
		};
		const inactiveInstance = new InactiveJS({
			timeout: 30 * 60 * 1000, //30 min
			onAway: onAway
		});
		console.log(inactiveInstance);
	},
    methods: {
		aprob() {
           alert('aprobado');
        },
		MenuToggle() {
            this.$store.commit('MenuToggle');
        },
        openLogin() {
            this.$store.commit('Mobile');
            this.displayRegister = false;
            this.displayLogin = true;
        },
        openRegister() {
            this.$store.commit('Mobile');
            this.displayLogin = false;
            this.displayRegister = true;
        },
        close() {
            this.displayLogin = false;
            this.displayRegister = false;
        },
        Logout() {
            this.$store.commit('Logout');
            this.$router.push({ path: '/' });
			this.$router.go(0);
        },
        Tiempo() {
			this.$toast.add({severity:'error', summary: 'Mensaje', detail:'El tiempo de inactividad fue alzanzado.', life: 3000});
            this.$store.commit('Logout');
            this.$router.push({ path: '/' });
        },
    }
}
</script>
