<template>
	<div :class="containerClass" @click="onWrapperClick">
        <Loading/>
		<AppTopBar @menu-toggle="onMenuToggle"/>
        <Sidebar v-model:visible="this.$store.state.error" header="Error de Sistema" position="top" style="height: auto">
            <p>{{$store.state.error}}</p>
        </Sidebar>
        <transition name="layout-sidebar" v-if="this.$store.state.autenticado">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <Message severity="warn" v-if="this.$store.state.conexion">Error de Conexión</Message>
                <AppProfile  v-if="this.$store.state.autenticado"/>
                <AppMenu :model="$store.state.menu" @menuitem-click="onMenuItemClick" />
            </div>
        </transition>

		<div class="layout-main" >
            <Toast/>
			<router-view style="padding: 1rem 1rem 1rem 1rem;"/>
		</div>

		<AppConfig :layoutMode="layoutMode" :layoutColorMode="layoutColorMode"  @layout-color-change="onLayoutColorChange" v-if="this.$store.state.admin"/>

		<AppFooter />
	</div>
</template>

<script>
import Loading from './components/Loading.vue';
import AppTopBar from './AppTopbar.vue';
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';

export default {
    data() {
        return {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: true,
            overlayMenuActive: true,
            mobileMenuActive: true,
        }
    },
    created() {
        this.$store.commit('Estatus');
        this.$store.commit('Mobile');
        this.$store.commit('Informacion');
        this.$store.commit('ValorPetro');
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.$store.state.overlayMenuActive = false;
                this.$store.state.mobileMenuActive = false;
            }
            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;
            if(this.$store.state.autenticado){
                if (this.isDesktop()) {
                     this.$store.state.staticMenuInactive = !this.$store.state.staticMenuInactive;
                }
                else {
                    this.$store.state.mobileMenuActive = !this.$store.state.mobileMenuActive;
                }
                
            } else {
                this.$store.state.staticMenuInactive = true;
                this.$store.state.overlayMenuActive = false;
                this.$store.state.mobileMenuActive = false;
            }
            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.$store.state.staticMenuInactive = true;
                //this.$store.state.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.$store.state.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.$store.state.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.$store.state.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.$store.state.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.$store.state.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.$store.state.mobileMenuActive,
				'p-input-filled': this.$appState.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.ripple === false
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
                //'layout-sidebar-dark': this.$store.state.layoutColorMode === 'dark',
                //'layout-sidebar-light': this.$store.state.layoutColorMode === 'light'
            }];
        },
    },
    beforeUpdate() {
        if (this.$store.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'Loading': Loading,
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
