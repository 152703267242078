<template>
	<div class="layout-footer" >
		<!--<img src="logo.jpg" style="height: 40px;"/>-->
		<span class="footer-text" style="margin-right: 1px; margin-left: 1px; color: #213a68">
            <a href="#" target="_blank" class="p-mr-3">
				<Avatar icon="bi bi-instagram" class="color_menu" shape="circle"/>
            </a>
            <a href="#" target="_blank" class="p-mr-3">
				<Avatar icon="pi pi-twitter" class="color_menu" shape="circle"/>
            </a>
            <a href="#" target="_blank" class="p-mr-3">
				<Avatar icon="bi bi-facebook" class="color_menu" shape="circle"/>
            </a>
		</span>
		<span class="footer-text" style="fontSize: 0.7rem; margin-right: 5px; margin-left: 5px;">
			Copyright 2024. Sistema en Construcción. Todos los derechos reservados.
		</span>
		<span class="footer-text" style="right: 1px">
			<a href="#" target="_blank" class="p-mr-3">
				<i class="pi pi-check-circle" style="fontSize: 1.5rem" v-if="state.estatus"></i>
				<i class="pi pi-exclamation-circle" style="fontSize: 1.5rem" v-else></i> 
			</a>
			<a href="#" target="_blank" class="p-mr-3">
				<i class="pi pi-mobile" style="fontSize: 1.5rem" v-if="state.mobile" @click="Scren"></i>
				<i class="pi pi-desktop" style="fontSize: 1.5rem" v-else @click="Scren"></i>
			</a>
			<span  class="footer-controls" style="fontSize: 1rem">
                WC{{ version }}
            </span>
		</span>
		
	</div>
</template>

<script>
	import store from './store';
	import packageJson from '../package.json';

	export default {
		data() {
			return {
				state: null,
				version: packageJson.version
			}
		},
		created() {
			this.state = store.state;
		},
		methods: {
			Scren(){
				alert('Pantalla: '+screen.width + " x " + screen.height);
			}
		}

	}
</script>

<style scoped>
	.color_menu {
		background-color: #396827; 
		color: white
	}

</style>