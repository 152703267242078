<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="model.filter(val => val.modulo == 2)[0].items" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" v-if="model.filter(val => val.modulo == 2).length"/>
		<AppSubmenu :items="model.filter(val => val.modulo > 2)" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		model: Array
	},
	data() {
		return {
		}
	},       
	created() {    
    },
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        }
    },
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>

</style>