import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'Inicio',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Home.vue')
    },
    {
        path: "/auditar",
        name: 'Auditar',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Auditar.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/usuarios",
        name: 'Usuarios',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Usuarios.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/roles",
        name: 'Roles',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Roles.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/modulos",
        name: 'Modulos',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Modulos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/paginas",
        name: 'Páginas',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Paginas.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/productos",
        name: 'Productos',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Productos.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/almacen",
        name: 'Almacen',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Almacen.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/ingreso",
        name: 'Ingreso',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Ingreso.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/despacho",
        name: 'Despacho',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Despacho.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/inventario",
        name: 'Inventario',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Inventario.vue'),
        meta: { authRequired: true }
    },
    {
        path: "/reporte",
        name: 'Reporte',
        component: () => import(/* webpackChunkName: "Home" */ './pages/Reporte.vue'),
        meta: { authRequired: true }
    },


];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
